

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
  }

  toggle(event) {
		const targetId = event.currentTarget.dataset.toggleTargetId;
		const targetElement = document.getElementById(targetId);
    var result;

		if (targetElement) {
			result = targetElement.classList.toggle("hidden");
		}
		var multiplier = 1;

		if (result) {
			// hidden was added, which means shrink the current window
			multiplier = 0.5;
		} else {
			multiplier = 2;
		}

		var currentHeight = window.outerHeight;
		var currentWidth = window.outerWidth;

		var newHeight = currentHeight * multiplier;
		window.resizeTo(currentWidth, newHeight);
  }
}
