import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
    width: Number,
    height: Number
  };

  open(event) {
    event.preventDefault(); // Prevent default link behavior
    
    const url = this.urlValue || "https://example.com";
    const width = this.widthValue || 800;
    const height = this.heightValue || 600;
    
    const features = `width=${width},height=${height},noopener,noreferrer,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no`;
    
    window.open(url, "popupWindow", features);
  }
}
