import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fade"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.element.style.transition = "opacity 1s ease-out";
      this.element.style.opacity = "0";
      setTimeout(() => this.element.remove(), 1000); // Remove after fade-out
    }, 2000); // 2-second delay
  }
}
