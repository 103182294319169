import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.scrollToBottom();
    this.observeMutations();
  }

  scrollToBottom() {
    this.element.scrollTo({ top: this.element.scrollHeight, behavior: "smooth" });
  }

  observeMutations() {
    const observer = new MutationObserver(() => this.scrollToBottom());
    observer.observe(this.element, { childList: true });
  }
}
